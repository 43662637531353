import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useMutation, useQuery } from "react-query";
import { loginMS } from "../../api/loginMS";
import { logout } from "../../api/logout";
import { IStorePolicyCode } from "../../constants/policies";
const useMsalUser = () => {
  const { accounts, instance, inProgress } = useMsal();
  const [token, setToken] = useState("");

  const msUser = useMemo(() => {
    const allAccounts = accounts;
    if (allAccounts.length > 0) {
      return allAccounts[0];
    }
  }, [accounts]);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          setToken(accessToken);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  const { data: dbUser, refetch: login } = useQuery(
    ["login", token],
    () => loginMS({ token: token }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!token,
    }
  );

  const logoutMutation = useMutation(() => logout(), {
    onSuccess: () => {
      instance.logoutRedirect();
    },
  });

  useEffect(() => {
    if (!msUser) {
      return;
    }
    if (!!!token) {
      return;
    }
    if (!dbUser) {
      login();
    }
  }, [dbUser, msUser, login, token]);

  const onLogin = useCallback(() => {
    instance.loginRedirect();
  }, [instance]);

  const onLogOut = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  return { msUser, token, onLogin, onLogOut, dbUser };
};

export type IUserBasedOnMSContext = ReturnType<typeof useMsalUser>;

export const UserBasedOnMSContext = createContext<
  IUserBasedOnMSContext | null | undefined
>(undefined);

export const useUserBasedOnMS = () => {
  const context = useContext(UserBasedOnMSContext);
  if (context === undefined) {
    throw new Error(
      "useUserBasedOnMS can only be used within a UserBasedOnMSContextProvider."
    );
  }
  return context;
};

interface IUserBasedOnMSContextProvideProps {
  children?: React.ReactNode;
}

export const UserBasedOnMSContextProvider: React.FC<
  IUserBasedOnMSContextProvideProps
> = ({ children }) => {
  const context = useMsalUser();

  return (
    <UserBasedOnMSContext.Provider value={{ ...context }}>
      {children}
    </UserBasedOnMSContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserBasedOnMSContext);

  const hasAccessToZoniStorePanel = useMemo(() => {
    if (
      !!context?.dbUser?.policies &&
      Array.isArray(context.dbUser.policies) &&
      context.dbUser.policies.length > 0
    ) {
      return true;
    }
    return false;
  }, [context?.dbUser]);

  const hasAccessTo = useCallback(
    (code: IStorePolicyCode) => {
      if (!!context?.dbUser?.policies.find((j) => j.storePolicyCode === code)) {
        return true;
      }
      return false;
    },
    [context?.dbUser]
  );

  const getAllowedBranchIds = useCallback(
    (code: IStorePolicyCode) => {
      const allAccessibleStores = context?.dbUser?.policies.filter(
        (j) => j.storePolicyCode === code
      );
      return allAccessibleStores?.map((j) => j.storeBranchId) ?? [];
    },
    [context?.dbUser]
  );

  return {
    dbUser: context?.dbUser,
    msUser: context?.msUser,
    hasAccessTo,
    getAllowedBranchIds,
    hasAccessToZoniStorePanel,
  };
};
