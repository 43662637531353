import { ILoginMSParams, ILoginMSResult } from "../pages/api/loginMS";
import { fetcher } from "./fetcher";

export const loginMS = async (payload: ILoginMSParams) => {
  const res = await fetcher.fetch("/api/loginMS", {
    method: "POST",
    body: JSON.stringify({
      ...payload,
    } as ILoginMSParams),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();
  if (!a.success) {
    throw new Error(JSON.stringify(a));
  } else {
    return a.data as ILoginMSResult;
  }
};
