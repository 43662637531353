import React from "react";
import { useQuery } from "react-query";
import { storeDiscountsGet } from "../api/storeDiscountsGet";

export const useStoreDiscounts = () => {
  const { data: storeDiscounts, isLoading: storeDiscountsIsLoading } = useQuery(
    ["storeDiscounts"],
    storeDiscountsGet,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return { storeDiscounts, storeDiscountsIsLoading };
};
