import { NotificationsProvider } from "@mantine/notifications";
import { showNotification } from "@mantine/notifications";

interface INotificationProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<INotificationProps> = ({
  children,
}) => {
  return (
    <NotificationsProvider position="top-center" transitionDuration={500}>
      {children}
    </NotificationsProvider>
  );
};

type INotificationType = "error" | "success" | "message";

export const notification = () => {
  return {
    show: ({
      message,
      title,
      type,
    }: {
      title: string;
      message: string;
      type: INotificationType;
    }) => {
      showNotification({
        title,
        message,
        color:
          type === "error"
            ? "red"
            : type === "message"
            ? "teal"
            : type === "success"
            ? "green"
            : "teal",
      });
    },
  };
};

export const notification2 = (type: INotificationType, message: string) => {
  showNotification({
    title:
      type === "error"
        ? "Error"
        : type === "success"
        ? "Success"
        : type === "message"
        ? "Info"
        : "",
    message,
    color:
      type === "error"
        ? "red"
        : type === "message"
        ? "teal"
        : type === "success"
        ? "green"
        : "teal",
  });
};
