import { fetcher } from "./fetcher";

export const logout = async () => {
  const res = await fetcher.fetch("/api/logout", {
    method: "POST",
    body: JSON.stringify({}),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();

  if (!a.success) {
    throw new Error(a?.error?.message);
  } else {
    return a.data;
  }
};
