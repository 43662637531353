import {
  IStoreDiscountsParams,
  IStoreDiscountsResult,
} from "../pages/api/storeDiscounts";
import { fetcher } from "./fetcher";

export const storeDiscountsGet = async (params: IStoreDiscountsParams) => {
  const res = await fetcher.fetch("/api/storeDiscounts", {
    method: "POST",
    body: JSON.stringify({ ...params }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();
  if (!a.success) {
    throw new Error(JSON.stringify(a));
  } else {
    return a.data as IStoreDiscountsResult;
  }
};
