import * as msal from "@azure/msal-browser";

const msalConfig: any = {
  auth: {
    clientId: process.env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_AZURE_AD_TENANT_ID}`,
    redirectUri: "/redirecting",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance, msal };
