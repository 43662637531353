import "../styles/nprogress.css";
import "../styles/globals.css";
import nProgress from "nprogress";
import "react-phone-input-2/lib/style.css";
import type { AppProps } from "next/app";
import Router from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { APP_STATE } from "../config/appState";
import { CartContextProvider } from "../components/app/Cart/useCart";
import { NotificationProvider } from "../contexts/notification";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../contexts/Msal/msal";
import { UserBasedOnMSContextProvider } from "../contexts/Msal/useMsalUser";
import { MantineProvider } from "@mantine/core";
Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

const queryClient = new QueryClient(); //default react query settings, edit this to change settings

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {APP_STATE === "Development" ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
      <MsalProvider instance={msalInstance}>
        <div className="font-poppins">
          <MantineProvider
            theme={{
              fontFamily: "poppins",
            }}
          >
            <UserBasedOnMSContextProvider>
              <NotificationProvider>
                <CartContextProvider>
                  <Component {...pageProps} />
                </CartContextProvider>
              </NotificationProvider>
            </UserBasedOnMSContextProvider>
          </MantineProvider>
        </div>
      </MsalProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
