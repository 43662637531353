import { useDeepCompareEffect, useLocalStorage } from "react-use";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { notification } from "../../../contexts/notification";
import { IStoreCart } from "../../../services/order/type";
import { useUser } from "../../../contexts/Msal/useMsalUser";
import { useStoreDiscounts } from "../../../hooks/useStoreDiscounts";
import { IStoreDiscount } from "../../../models/constants/storeDiscounts";

const useCart = () => {
  const { dbUser } = useUser();

  const { storeDiscounts } = useStoreDiscounts();

  const [
    cartFromLocalStorage,
    setCartFromLocalStorage,
    removeCartFromLocalStorage,
  ] = useLocalStorage<IStoreCart>("cart", []);

  const [cart, setCart] = useState<IStoreCart>(
    () => cartFromLocalStorage ?? []
  );

  const isCartEmpty = useMemo(() => {
    if (!!!cart || cart.length === 0) {
      return true;
    }
    return false;
  }, [cart]);

  // useDeepCompareEffect(() => {
  //   if (!cartFromLocalStorage) {
  //     return;
  //   }
  //   setCart((_) => cartFromLocalStorage);
  // }, [cartFromLocalStorage,cart]);

  useDeepCompareEffect(() => {
    if (!cart) {
      return;
    }
    setCartFromLocalStorage(cart);
  }, [cart, cartFromLocalStorage]);

  const { canCheckOut, canCheckOutError } = useMemo(() => {
    if (cart.length === 0) {
      return {
        canCheckOut: false,
        canCheckOutError: "No items in the cart",
      };
    }
    if (cart.some((p) => (p?.quantityAvailable ?? 99) < p.quantity)) {
      return {
        canCheckOut: false,
        canCheckOutError:
          "Some products are out of stock. Please remove them before checking out.",
      };
    }

    return { canCheckOut: true, canCheckOutError: "" };
  }, [cart]);

  // useInterval(() => setCartFromLocalStorage((_) => cart), 1000);

  const addToCart = useCallback(
    (newProd: {
      productId: string;
      storeProductId: number;
      quantity: number;
      price: string;
      quantityAvailable: number;
    }) => {
      const notify = notification();

      const prevProd = cart.find((p) => p.productId === newProd.productId);
      const currentQuantity = prevProd?.quantity ?? 0;

      if (currentQuantity + newProd.quantity > newProd?.quantityAvailable) {
        notify.show({
          message:
            "You cannot add more to cart because this item is out of stock.",
          title: "Failed Adding to cart",
          type: "error",
        });
        return;
      }

      setCart((cart) => {
        // debugger;

        if (isCartEmpty) {
          return [newProd];
        }

        const prevProd = cart.find((p) => p.productId === newProd.productId);

        if (prevProd) {
          return cart.map((p) => {
            if (p.productId === prevProd.productId) {
              return { ...p, quantity: p.quantity + newProd.quantity };
            }
            return p;
          });
        }
        return [...cart, newProd];
      });

      notify.show({
        message: "Successfully added to cart.",
        title: "Cart Updated.",
        type: "success",
      });
    },
    [setCart, cart, isCartEmpty]
  );

  const removeCartItem = useCallback(
    ({ productId, removeAll }: { productId: string; removeAll?: boolean }) => {
      if (
        removeAll ||
        cart?.find((i) => i.productId === productId)?.quantity! <= 1
      ) {
        setCart((cart) => {
          return cart?.filter((i) => productId !== i.productId);
        });
        return;
      }
      setCart((cart) =>
        cart.map((i) => {
          if (i.productId === productId) {
            return { ...i, quantity: i.quantity - 1 };
          }
          return i;
        })
      );
    },
    [setCart, cart]
  );

  const emptyCart = useCallback(() => {
    setCart([]);
  }, []);

  const productCount = cart?.length ?? 0;

  const total = useMemo(() => {
    const total = cart.reduce((acc, cartItem) => {
      return acc + cartItem.quantity * parseFloat(cartItem.price);
    }, 0);
    return total.toFixed(2);
  }, [cart]);

  const discountChosen = useMemo(() => {
    if (dbUser?.role !== "student") {
      return;
    }
    return storeDiscounts?.find((d) => d.discountCode === "STDISCOUNT");
  }, [storeDiscounts, dbUser?.role]);

  const discountedAmount = useMemo(() => {
    if (!!discountChosen) {
      const discountAmt =
        (discountChosen.discountPercent / 100) * parseFloat(total);
      return discountAmt.toFixed(2);
    }
  }, [total, discountChosen]);

  const subTotal = useMemo(() => {
    if (!discountedAmount) {
      return total;
    }
    const subTotal = parseFloat(total) - parseFloat(discountedAmount);
    return subTotal.toFixed(2);
  }, [total, discountedAmount]);

  return {
    cart,
    addToCart,
    productCount,
    removeCartItem,
    emptyCart,
    total,
    subTotal,
    discountedAmount,
    discountChosen,
    canCheckOut,
    canCheckOutError,
    isCartEmpty,
  };
};

type AddToCart = (newProd: {
  storeProductId: number;
  productId: string;
  quantity: number;
  price: string;
  quantityAvailable: number;
}) => void;
export interface ICartContext {
  cart?: IStoreCart;
  total?: string;
  discountedAmount?: string;
  discountChosen?: IStoreDiscount;
  subTotal: string;
  addToCart: AddToCart;
  removeCartItem: (remove: { productId: string; removeAll?: boolean }) => void;
  emptyCart: () => void;
  canCheckOut: boolean;
  canCheckOutError?: string;
  isCartEmpty?: boolean;
}

export const CartContext = createContext<ICartContext | null | undefined>(
  undefined
);

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCartContext can only be used within a CartProvider.");
  }
  return context;
};

interface ICartContextProvideProps {
  children?: React.ReactNode;
}

export const CartContextProvider: React.FC<ICartContextProvideProps> = ({
  children,
}) => {
  const context = useCart();

  return (
    <CartContext.Provider value={{ ...context }}>
      {children}
    </CartContext.Provider>
  );
};
